<template>
    <div>
        <div class="card">
            <div class="bg-blue-light px-1 rounded pb-2">
                <TitleButton
                    class="mt-1"
                    btnTitle="Go Back"
                    title="Update Receipt"
                    @onClickCloseButton="goToList"
                />

                <div class="row mt-1">
                    <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                        <label for="colFormLabel" class="col-form-label">Date</label>
                        <input
                            class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                            tabindex="1"
                            type="date"
                            placeholder=""
                            v-model="receipt.date"
                        >
                    </div>
                    <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                        <label for="colFormLabel" class="col-form-label">Voucher</label>
                        <input type="hidden" class="form-control invoice-edit-input date-picker flatpickr-input"
                               readonly="readonly">
                        <div
                            class="input-group input-group-merge invoice-edit-input-group has-validation"
                            :class="isEmptyVoucher ? 'is-invalid' : ''"
                        >
                            <div class="input-group-text" style="background-color: #efefef; outline: none">
                                <span :class="isEmptyVoucher ? 'text-danger' : ''">{{ receipt.voucher_prefix }}-</span>
                            </div>
                            <input
                                    type="text"
                                    class="form-control invoice-edit-input"
                                    aria-describedby="validationVoucherNameFeedback"
                                    placeholder=""
                                    v-model="receipt.voucher_serial"
                                    :class="isEmptyVoucher ? 'is-invalid' : ''"
                                    readonly
                            >
                            <div id="validationVoucherNameFeedback" class="invalid-feedback">
                                Voucher number is required
                            </div>
                        </div>
                    </div>
                    <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                        <label for="colFormLabel" class="col-form-label">Received in</label>
                        <v-select
                                placeholder="Select Cash & Bank Account"
                                class="w-100"
                                v-model="debit.account_head_id"
                                :options="accountHeads"
                                label="name"
                                :reduce="name => name.id"
                        />
                    </div>

                    <div class="col-12 col-sm-6 col-md-4 col-lg-3">

                        <div class="d-flex align-items-center justify-content-between filter-label">
                            <label for="colFormLabel" class="col-form-label">Party: </label>
                            <span class="filter-search-icon cursor-pointer" @click="onOpenContactSearchModal">
                                <i class="fas fa-search"></i>
                            </span>
                        </div>

                        <AsyncSelect
                            placeholder="Select Party"
                            v-model="contactProfile"
                            :api-service="fetchContactProfiles"
                            :format-label="formatPatientLabel"
                        />
                    </div>

                    <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                        <label for="colFormLabel" class="col-form-label">Business</label>
                        <v-select
                                placeholder="Select Business"
                                class="w-100"
                                :options="businesses"
                                label="name"
                                :reduce="name => name.id"
                                v-model="receipt.business_id"
                        />
                    </div>
                </div>
            </div>

            <div class="card p-2">
                <template v-for="(item, index) in receipt.ledgers" :key="index">
                    <AddFormElement
                            class="mb-2"
                            :accountHeads="accountHeads"
                            v-if="item.debit == null"
                            :index="index"
                            :item="item"
                            @onClose="onClose"
                    />
                </template>

                <div class="row me-1 mt-3">
                    <div class="col-12 col-sm-4 col-lg-8 mb-1 mb-sm-0">
                        <AddButton title="Add line" @onClickAdd="onClickAdd"/>
                    </div>
                    <div class="col-6 col-sm-4 col-lg-4"><input readonly type="number" class="form-control text-end"
                                                                :value="totalCredit"></div>
                    <!-- <div class="col-6 col-sm-4 col-lg-2"><input readonly type="number" class="form-control" :value="totalCredit"></div> -->
                </div>

                <div class="row mt-3">
                    <div class="col-12">
                        <div class="mb-2">
                            <label for="note" class="form-label fw-bold">Memo</label>
                            <textarea v-model="receipt.note" placeholder="Memo" class="form-control" rows="2"
                                      id="note"></textarea>
                        </div>
                    </div>
                </div>

                <div class="mt-2 text-center">
                    <hr class="mb-1">
                    <button
                        :disabled="receiptLoading || isEmptyVoucher"
                        @click="updateReceipt()"
                        class="btn btn-primary me-1"
                    >
                        <div v-if="receiptLoading" class="spinner-border spinner-border-sm text-light" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        Update
                    </button>
                    <button
                            @click="goToList"
                            class="btn btn-outline-secondary cancel-btn"
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </div>
        <VoucherValidationModal
                ref="voucherValidationModal"
                :voucherQuery="voucherQuery"
                @on-press-ok="setVoucherNumberAndDate(true)"
        />
        <ContactDropdownFilter
            v-if="store.state.isModalOpenThree"
            @onSearchContact="onSearchContact"
            :isFilterable="true"
            :companyRoles="companyRoles"
        />
    </div>
</template>

<script>
import handleReceipt from '@/services/modules/receipt'
import { inject } from 'vue'

import handleContact from '@/services/modules/contact'
import handleCBusinesses from '@/services/modules/businesses'

import TitleButton from '@/components/atom/TitleButton'
import AddFormElement from '@/components/molecule/company/receipt/AddFormElement'
import AddButton from '@/components/atom/AddButton'
import {editVoucherNumber, generateQuery, generateVoucherNumber} from "@/services/utils/voucherNumberGenerator";
import VoucherValidationModal from "@/components/molecule/company/voucher/VoucherValidationModal.vue";
import figureFormatter from "@/services/utils/figureFormatter";
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import ContactDropdownFilter from "@/components/atom/Contact/ContactDropdownFilter.vue";
import {useStore} from "vuex";
import {useAsyncDropdownHelper} from "@/services/utils/asyncDropdownHelper";
import handleRole from "@/services/modules/role";
import handleJournal from '@/services/modules/journal'

export default {
    name: 'ReceiptEdit',
    components: {
        ContactDropdownFilter,
        AsyncSelect,
        VoucherValidationModal,
        TitleButton,
        AddFormElement,
        AddButton
    },
    data: () => ({
        accountHeads: [],
        contactProfiles : [],
        businesses : [],
        saveNewLoader: false,
        isEmptyVoucher: false,
        companyRoles: [],
        contactProfile: null,
        debit: {
            account_head_id: null
        },
        receipt: {
            voucher_prefix : '',
            voucher_serial : '',
            voucher_no : '',
            voucher_type: 'receipt_voucher',
            contact_profile_id: null,
            business_id: null,
            ledgers: []
        }
    }),
    computed: {
        isLoading () {
            return this.receiptLoading || this.saveNewLoader
        },
        start () {
            return this.$route.query.start
        },
        end () {
            return this.$route.query.end
        },
        general () {
            return this.receipt.ledgers
        },
        totalCredit () {
            let total = 0;
            this.general.map(i => {
                if(i.credit) total += i.credit
            })
            return total
        },
        voucherQuery () {
            return generateQuery(this.$route.params.companyId, 'receipt_voucher', 'general_receipt')
        }
    },

    watch: {
        'receipt.voucher_prefix':function () {
            this.concatVoucher()
        },

        'receipt.voucher_serial':function () {
            this.concatVoucher()
        },
        contactProfile(newContact) {
            if(!newContact) {
                this.receipt.contact_profile_id = null;
                return;
            }
            this.receipt.contact_profile_id = newContact.id;
        }
    },
    methods: {
        concatVoucher(){
            this.isEmptyVoucher = false;

            if(! this.receipt.voucher_serial) {
                this.isEmptyVoucher = true;
                return ;
            }

            let voucherSerial = this.decimalFormat(this.receipt.voucher_serial.toString().replace(/[^0-9\.]+/g, ''))
            if(voucherSerial === '') {
                this.isEmptyVoucher = true;
            }
            this.receipt.voucher_serial = voucherSerial;
            this.receipt.voucher_no = `${this.receipt.voucher_prefix}-${voucherSerial}`;
        },
        
        async getReceipt() {
           // return;
            try {
                this.receiptLoading = true
                let res = await this.fetchSingleReceipt(this.$route.params.receiptId)
                if(!res.status) {
                    this.showError(res.message)
                }
                if(res.status) {
                    this.receipt = res.data
                    this.contactProfile = res.data.ledgers && res.data.ledgers.length ?  res.data.ledgers[0].party : null;
                    this.setData()
                }
            } catch (err) {
                if(!err.response) {
                    this.showError('Something is wrong. Check your connectivity!!')
                }
                if(err.response) {
                    this.showError(err.response.message)
                }
            } finally {
                this.receiptLoading = false
            }
        },
        async getAccountHeads() {
           // return;
            try {
                this.receiptLoading = true
                let accountHeadQuery = '?company_id=' + this.$route.params.companyId + '&cash_bank_hide=yes&status=active';

                let res = await this.fetchAccountHeadsList(accountHeadQuery)
                if(!res.status) {
                    this.showError(res.message)
                }
                if(res.status) {
                    this.accountHeads = res.data
                }
            } catch (err) {
                if(!err.response) {
                    this.showError('Something is wrong. Check your connectivity!!')
                }
                if(err.response) {
                    this.showError(err.response.message)
                }
            } finally {
                this.receiptLoading = false
            }
        },
        async getContactProfiles() {
    try {
      let companyId = this.$route.params.companyId
      let contactId = '';
      let q = '';
      let query = '?company_id=' + companyId + '&q=' + q + '&contact_id' + contactId;
      let res = await this.fetchContactProfiles(query)

      if(!res.status) {
      }
      if(res.status) {
        this.contactProfiles = res.data;
      }

    } catch (err) {
      if(!err.response) {
        this.showError('Something is wrong. Check your connectivity!!')
      }
      if(err.response) {
        this.showError(err.response.message)
      }
    } finally {
      this.loading = false
    }
  },
        async getBusinesses(){
    try {
      let companyId = this.$route.params.companyId
      let res = await this.fetchBusinessList('?company_id=' + companyId)

      if(!res.status) {
      }
      if(res.status) {
        this.businesses = res.data;
      }

    } catch (err) {
      if(!err.response) {
        this.showError('Something is wrong. Check your connectivity!!')
      }
      if(err.response) {
        this.showError(err.response.message)
      }
    } finally {
      this.loading = false
    }

  },
        setVoucherNumberAndDate(withoutDate = false) {
            new Promise(async (resolve, reject) => {
                try {
                    if(! withoutDate) {
                        this.receipt.date = new Date().toISOString().split('T')[0]
                    }
                    let query = this.voucherQuery;
                    let voucher = await generateVoucherNumber(query);
                    this.receipt.voucher_prefix = voucher.prefix;
                    this.receipt.voucher_serial = voucher.serial;
                    resolve();
                } catch (err) {
                    reject(err);
                }
            })
        
        },
        goToList() {
            this.$router.push({name: 'receipt-voucher-list', 
                params: {
                    companyId: this.$route.params.companyId,
                    moduleId: this.$route.params.moduleId,
                    menuId: this.$route.params.menuId,
                    pageId: this.$route.params.pageId
                },
                query: {
                    start: this.start,
                    end: this.end
                }
            
            })
        },
        onClose(index) {
            this.receipt.ledgers.splice(index, 1)
        },
        onClickAdd() {
            this.receipt.ledgers.push({
                account_head_id: null,
                debit: null,
                credit: null,
                note: '',
                tax_rate: null,
                taxable_amount: null
            })
        },
        setData() {
            let ledger = this.general.find(i => i.credit === null)
            if(ledger) this.debit.account_head_id = ledger.account_head_id
        },
        getFormData () {
            let formData = new FormData();
            this.receipt._method = 'PATCH';
            Object.keys(this.receipt).forEach(i => {
                if(i !== 'ledgers' && i !== 'business_id' && i !== 'contact_profile_id') {
                    formData.append(i, this.receipt[i])
                }
            });
            if(this.receipt.business_id) formData.append('business_id', this.receipt.business_id)
            if(this.receipt.contact_profile_id) formData.append('contact_profile_id', this.receipt.contact_profile_id)
            let mop_account_head_id = this.debit.account_head_id;
            let generalLedgerData = this.general.map((ledger) => {
                if (ledger.credit === null){
                  ledger.note = this.receipt.note;
                }
                ledger.contact_profile_id = this.receipt.contact_profile_id;
                ledger.business_id = this.receipt.business_id;
                ledger.mop_account_head_id = mop_account_head_id;
                return ledger
            })
            let debitObj = generalLedgerData.find(i => i.credit === null)
                debitObj.account_head_id = this.debit.account_head_id
                debitObj.mop_account_head_id = null
                debitObj.debit = this.totalCredit
            formData.append('general_ledgers', JSON.stringify(generalLedgerData))
            return formData;
        },
        async updateReceipt () {
            this.concatVoucher();
            this.receipt.company_id = this.$route.params.companyId;
            let data = this.getFormData();
            this.receiptLoading = true
            try {
                let res = await this.editReceipt(this.receipt.id, data)
                if(!res.status) {
                    this.showError(res.message)
                }
                if(res.status) {
                    this.showSuccess(res.message)
                     setTimeout(() => {this.goToList()},1500)
                }
            } catch (err) {
                if(!err.response) {
                    this.showError('Something is wrong. Check your connectivity!!')
                    return ;
                }

                if(err.response && err.response.status !== 406) {
                    this.showError(err.response?.data.message)
                }
                if(err.response.data.data && err.response.data.data.show_modal) {
                    this.$refs.voucherValidationModal.openModal(err.response?.data.message)
                }
            } finally {
                this.receiptLoading = false
            }
        },
        onOpenContactSearchModal () {
            this.$store.state.isModalOpenThree = true;
        },
        async getCompanyRoles () {
            const res = await this.fetchCompanyDefaultRoles(`?company_id=${this.$route.params.companyId}`);
            if (res.status) {
                this.companyRoles = res.data
            }
        },
        onSearchContact (contact) {
            this.contactProfile = contact
        }
    },

    setup() {
        const showError =  inject('showError');
        const showSuccess =  inject('showSuccess');
        const {decimalFormat} = figureFormatter();
        const store = useStore()

        const { formatPatientLabel } = useAsyncDropdownHelper();
        const { fetchCompanyDefaultRoles } = handleRole();
        const { fetchAccountHeadsList } = handleJournal()

        const {
            editReceipt,
            fetchSingleReceipt,
            receiptLoading
        } = handleReceipt()

        const {
          fetchContactProfiles,
        } = handleContact()

        const {
          fetchBusinessList,
        } = handleCBusinesses()

        return {
            store,
            fetchAccountHeadsList,
            fetchContactProfiles,
            fetchBusinessList,
            editReceipt,
            fetchSingleReceipt,
            decimalFormat,
            receiptLoading,
            showError,
            showSuccess,
            formatPatientLabel,
            fetchCompanyDefaultRoles
        }
    },

    async mounted() {
        await this.getReceipt()
        await this.getAccountHeads()
        await this.getBusinesses()
        await this.getCompanyRoles()
    
        let voucher = editVoucherNumber(this.receipt.voucher_no);
        this.receipt.voucher_prefix = voucher.prefix;
        this.receipt.voucher_serial = voucher.serial;
    }
}
</script>

<style scoped>
    .cancel-btn{
        background-color: #F0F2F5; color: #7D7D7D;
    }
    .cancel-btn:hover{
        background-color: #e9edf5;
    }
    hr{
        margin: 0 -3%;
        width: 106%;
    }
</style>
